@import "../../_base";

.outer-container {
  margin-top: 5vw;
  padding-top: 6vw;
  background-color: $light-gray-background;
  display: grid;
  grid-template-columns: $grid-template-columns;
  grid-template-rows: repeat(3, fr);
  padding-bottom: 2vw;
  height: 55vw;
}

.prov-title {
  grid-area: 1 / 7 / span 1 / span 3;
  background-repeat: no-repeat;
  min-height: 20vw;
  margin-left: -0.6vw;
  width: 101.5%;
  align-self: end;
}

.intro-para {
  grid-area: 2 / 7 / span 1 / span 3;
  font-family: $font-family-body;
  font-size: $size-text-body;
  font-weight: 500;
  line-height: 1.4em;
  text-align: justify;
  margin-left: -6.2vw;
}

.province-svg {
  grid-area: 1 / 4 / span 2 / span 3;
  height: 100%;
  background-repeat: no-repeat;
}

.infrographic-section {
  margin-top: 2vw;
  min-height: 35vw;
  margin-left: -1.5vw;
  width: 150%;
  align-self: end;
  background-repeat: no-repeat;
}

.infro1 {
  grid-area: 3 / 4 / span 1 / span 2;
}

.container-right {
  grid-area: 1 / 7 / span 2 / span 6;
  padding: 1vw;
}

.outer-container {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }
}
