@import "../../_base";

.label {
  font-family: $font-family-header;
}

.bc-hospitalization-graph {
  min-height: 40vw;
}

text.recharts-text.recharts-label {
  font-weight: normal;
}
.title-hospitalization-graph {
  text-align: center;
  position: relative;
  margin-top: -4vw;
  margin-right: 7vw;
  margin-bottom: 2vw;
  font-family: $font-family-header;
  padding: 2vw;
  font-size: $size-chart-title;
  font-weight: bold;
}
.recharts-yAxis
  .recharts-cartesian-axis-ticks
  .recharts-cartesian-axis-tick:first-child
  line {
  stroke: none;
}

.recharts-yAxis
  .recharts-cartesian-axis-ticks
  .recharts-cartesian-axis-tick:first-child
  text {
  font-size: 0;
}

.recharts-cartesian-axis-tick {
  font-family: $font-family-body;
}

.legend-item {
  font-family: $font-family-body;
}

div.recharts-legend-wrapper {
  position: relative;
  left: 1020 !important;
}

.y-axis-responsive {
  font-size: 1vw;
  font-family: $font-family-header;
  -webkit-transform: rotate(-90deg); /* Safari */
  -moz-transform: rotate(-90deg); /* Firefox */
  -ms-transform: rotate(-90deg); /* IE */
  -o-transform: rotate(-90deg); /* Opera */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); /* Internet Explorer */
  position: absolute;

  top: 35%;
  left: -2vw;
}
