@import '../../_base';

.about-container {
  background-image: url('/map-image/MOBILE-map.png');
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  grid-template-rows: auto;
  padding-top: 10vw;
  overflow: hidden;
  min-height: 100vh;
}

//related publications section
.publications {
  grid-column: 3 / span 4;
  background-color: white;
  margin: 2vw;
  justify-self: center;
  display: grid;
  margin-bottom: 5vw;
  padding: 3vw;
}

.rel-container {
  display: block;
}
.rel {
  display: inline-block;
  text-align: justify;
  height: 4.7vw;
  margin-bottom: 4vh;
}

.about-list {
  list-style-type: none !important;
  justify-self: left;
  padding-left: 0vw;
}
.about-link {
  color: $blue-green;
  text-decoration: underline;
  font-family: $font-family-body;
  font-size: 1.3vw !important;
  cursor: pointer;
}
.about-link:hover {
  color: $bright-lime;
}

//acknowledgements section
.acknowledgments {
  grid-column: 7 / span 4;
  background-color: white;
  margin: 2vw;
  justify-self: stretch;
  display: grid;
  margin-bottom: 5vw;
  padding: 3vw;
}

.ack-container {
  display: block;
}

.ack {
  display: inline-block;
  text-align: justify;
  margin-bottom: 4vh;
  letter-spacing: 0.06vw;
}

.about-para {
  font-family: $font-family-body;
  font-size: 1.4vw;
}
.logos-container {
  grid-row: 3;
  display: grid;
  grid-template-columns: repeat (5, 1fr);
  overflow: visible;
}

.logo-bcch {
  height: 4vw;
  width: 100%;
  margin-left: 5vw;
  margin-top: 2.6vw;
  grid-column: 1 / span 1;
}

.logo-cihr {
  //need to do media queries here
  margin-left: 0.5vw;
  height: 8vw;
  width: 120%;
  grid-column: 2 / span 4;
  //shrink PNG image by 50%
  -webkit-transform: scale(0.4); /* Saf3.1+, Chrome */
  -moz-transform: scale(0.4); /* FF3.5+ */
  -ms-transform: scale(0.4); /* IE9 */
  -o-transform: scale(0.4); /* Opera 10.5+ */
  transform: scale(0.4);
  /* IE6–IE9 */
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713,SizingMethod='auto expand');
}

//contact section
.contact {
  grid-column: 3 / span 8;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  display: grid;
  margin-bottom: 5vw;
}

.contact-1-container {
  grid-column: 1 / span 1;
  margin-top: 2vw;
  margin-left: 3vw;
}

.contact-2 {
  grid-column: 2 / span 1;
  align-self: center;
  padding: 3vw;
}
.contact-3 {
  grid-column: 3 / span 1;
  align-self: center;
  border-left: 0.3vw solid $bright-lime;
  padding: 3vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
  display: grid;
  align-content: center;
}
.contact-4 {
  grid-column: 4 / span 1;
  align-self: center;
  border-left: 0.3vw solid $bright-lime;
  padding: 3vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
  display: grid;
  align-content: center;
}

//general styling
.about-header {
  font-family: $font-family-header;
  font-weight: bold;
  color: $title-h1;
  border-bottom: $underline;
  font-size: $size-h2;
}

.about-sub-header {
  color: $title-h1;
  font-family: $font-family-body;
  font-size: 0.8vw;
}

.about-sub-info {
  font-family: $font-family-body;
  font-weight: bold;
  font-family: $font-family-body;
  font-size: 1.3vw;
}

//tablet landscape view
@media (max-width: 1025px) {
  .about-container {
    grid-template-columns: repeat(2, 30px) repeat(8, 1fr) repeat(2, 30px);
    // height: 100vh;
  }
  .logo-cihr {
    margin-left: -13vw;
    height: 17vw;
    width: 230%;
    -webkit-transform: scale(0.2); /* Saf3.1+, Chrome */
    -moz-transform: scale(0.2); /* FF3.5+ */
    -ms-transform: scale(0.2); /* IE9 */
    -o-transform: scale(0.2); /* Opera 10.5+ */
    transform: scale(0.2);
  }
  .logo-bcch {
    margin-top: 6.7vw;
    margin-left: 4vw;
    // background: blue;
  }
}

//tablet portrait view
@media (max-width: 768px) {
  .about-container {
    grid-template-columns: repeat(2, 15px) repeat(8, 1fr) repeat(2, 15px);
    margin-top: 5vw;
    height: 100vh;
  }
  .publications {
    margin-top: 2vw;
  }
  .acknowledgments {
    margin-top: 2vw;
  }
  .contact {
    margin-top: 8vw;
    margin-bottom: 9vw;
  }
  .about-para {
    font-size: 1.6vw;
  }

  .contact {
    height: 20vh;
  }

  .logo-cihr {
    margin-left: -17vw;
    height: 17vw;
    width: 230%;
    -webkit-transform: scale(0.2); /* Saf3.1+, Chrome */
    -moz-transform: scale(0.2); /* FF3.5+ */
    -ms-transform: scale(0.2); /* IE9 */
    -o-transform: scale(0.2); /* Opera 10.5+ */
    transform: scale(0.2);
  }
  .logo-bcch {
    margin-top: 6.7vw;
    margin-left: 4.2vw;
    // background: green;
  }
}

@media (min-width: 1025px) {
  .about-container {
  }
  .publications {
    padding: 1.5vw;
  }

  .acknowledgments {
    padding: 1.5vw;
  }

  .contact-1-container {
    grid-column: 1 / span 1;
    margin-top: 2vw;
    margin-left: 1.5vw;
  }
  .logo-cihr {
    margin-left: -12vw;
    height: 13vw;
    width: 230%;
    -webkit-transform: scale(0.24); /* Saf3.1+, Chrome */
    -moz-transform: scale(0.24); /* FF3.5+ */
    -ms-transform: scale(0.24); /* IE9 */
    -o-transform: scale(0.24); /* Opera 10.5+ */
    transform: scale(0.24);
  }
  .logo-bcch {
    margin-top: 4.7vw;
    margin-left: 4.2vw;
    // background: orange;
  }
}

@media (min-width: 1350px) {
  .publications {
    padding: 2.8vw;
  }

  .acknowledgments {
    padding: 2.8vw;
  }
  .logo-cihr {
    margin-left: -9vw;

    -webkit-transform: scale(0.35); /* Saf3.1+, Chrome */
    -moz-transform: scale(0.35); /* FF3.5+ */
    -ms-transform: scale(0.35); /* IE9 */
    -o-transform: scale(0.35); /* Opera 10.5+ */
    transform: scale(0.35);
  }

  .logo-bcch {
    margin-top: 3.4vw;
    margin-left: 3.2vw;
    // background: yellow;
  }
}

@media (min-width: 1680px) {
  .publications {
    padding: 3vw;
  }

  .acknowledgments {
    padding: 3vw;
  }
  .logo-cihr {
    margin-left: -7vw;
    -webkit-transform: scale(0.45); /* Saf3.1+, Chrome */
    -moz-transform: scale(0.45); /* FF3.5+ */
    -ms-transform: scale(0.45); /* IE9 */
    -o-transform: scale(0.45); /* Opera 10.5+ */
    transform: scale(0.45);
  }
  .logo-bcch {
    // background: pink;
    margin-top: 3.5vw;
    margin-left: 3vw;
  }
}

@media (min-width: 2200px) {
  .logo-cihr {
    margin-left: -2vw;
    -webkit-transform: scale(0.65); /* Saf3.1+, Chrome */
    -moz-transform: scale(0.65); /* FF3.5+ */
    -ms-transform: scale(0.65); /* IE9 */
    -o-transform: scale(0.65); /* Opera 10.5+ */
    transform: scale(0.6);
  }
  .logo-bcch {
    // background: blue;
    margin-top: 3vw;
    margin-left: 2.7vw;
  }
}

@media (min-width: 2560px) {
  .logo-bcch {
    margin-top: 3vw;
    margin-left: 2.7vw;
  }
}
