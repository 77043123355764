@import "../../_base";

.footer {
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  background: $mid-blue;
  height: 6vw;
  align-content: center;
}

.contact-link {
  grid-column: 3 / span 4;
  color: white;
  text-decoration: underline;
  font-family: $font-family-body;
  font-size: 1vw;
  cursor: pointer;
}
.contact-link:hover {
  color: #3e7cb1;
}

.copyright {
  grid-column: 7 / span 4;
  color: white;
  font-family: $font-family-body;
  text-align: right;
  font-size: 1vw;
}

.copyright-icon {
  color: white;
}

@media (max-width: 768px) {
}
