@import "../../_base";

.whole-home-intro {
  display: grid;
  grid-template-columns: $grid-template-columns;
  grid-template-rows: auto;
  overflow: visible;
  margin-top: 5vw;
}

.para-after {
  grid-column: 4 / span 6;
  font-size: $size-text-body;
  font-family: $font-family-body;
  text-align: left;
  text-align: justify;
  margin-top: 4vw;
  justify-self: center;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .whole-home-intro {
    margin-top: 8vw;
    grid-template-columns: repeat(2, 15px) repeat(8, 1fr) repeat(2, 15px); //make width of component larger by reducing border gutters
  }
  .bar-chart-intro-title {
    margin-right: -8vw;
  }
  .barchart-home-intro {
    margin-left: -6vw;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .bar-chart-intro-title {
    margin-right: -9vw;
  }
  .bar-chart-intro-title {
    margin-bottom: -21vw;
    margin-right: -8vw;
  }
  .whole-home-intro {
    margin-top: 7vw;
    grid-template-columns: repeat(2, 30px) repeat(8, 1fr) repeat(2, 30px); //make width of component larger by reducing border gutters
  }
  .barchart-home-intro {
    margin-left: -5vw;
  }
}

.bar-chart-intro-title {
  padding-bottom: 4vw;
  color: $title-h1;
  grid-column: 4 / span 6;
  font-family: $font-family-header;
  font-size: $size-text-body;
  text-align: center;
  margin-bottom: -1vw;
}

.barchart-home-intro {
  grid-column: 4 / span 6;
  margin-bottom: 2vw;
  align-self: center;
}
