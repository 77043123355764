@import "../../_base";

.info-container {
  // margin-top: 7vw;
  background-color: $light-gray-background;
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  height: 100%;
  overflow: hidden;
}

.infrographic-section {
  background-repeat: no-repeat;
  grid-column: 4 / span 6;
  width: 100%;
  margin-left: 1vw;
}

@media (min-width: 1024px) {
  .info-container {
    margin-top: 5vw;
  }
  .infrographic-section {
    margin-left: -5vw;
    width: 120%;
    height: 103.5%;
    margin-top: 3vw;
  }
}

@media (min-width: 1200px) {
  .info-container {
    margin-top: 10vw;
  }
  .infrographic-section {
    margin-left: -8vw;
    width: 130%;
    height: 100%;
    margin-top: 9vw;
  }
}

@media (min-width: 1440px) {
  .info-container {
    margin-top: 15vw;
  }
  .infrographic-section {
    margin-left: -10vw;
    width: 130%;
    height: 100%;
    margin-top: 11vw;
  }
}

@media (min-width: 2011px) {
  .info-container {
    margin-top: 20vw;
  }
  .infrographic-section {
    margin-left: -10vw;
    width: 130%;
    height: 100%;
    margin-top: 13vw;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .info-container {
    grid-template-columns: repeat(2, 30px) repeat(8, 1fr) repeat(2, 30px);
  }
  .infrographic-section {
    margin-left: -5vw;
    width: 120%;
    height: 95%;
    margin-top: 10vw;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .info-container {
    grid-template-columns: repeat(2, 30px) repeat(8, 1fr) repeat(2, 30px);
  }
  .infrographic-section {
    height: 103%;
    margin-top: 5vw;
    width: 115%;
    margin-left: -5vw;
  }
}
