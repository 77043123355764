@import "../../_base";

.linechart-background {
  min-height: 60vw;
  margin-top: 4vw;
  overflow: hidden;
  padding-top: 2vw;
  display: grid;
  grid-template-columns: $grid-template-columns;
  grid-template-rows: auto;
  margin-top: 5vw;
  transition: background 4s ease;
  -webkit-transition: background 4s ease;
  -moz-transition: background 4s ease;
  -o-transition: background 4s ease;
  -ms-transition: background 4s ease;
}

.injury-title {
  color: $title-h1;
  font-family: $font-family-header;
  font-size: $size-subheadings;
  font-weight: bold;
  line-height: 1.5;
  background-color: $gray-background;
  text-align: left;
  align-items: left;
  border-bottom: $underline;
}

.injury-title {
  grid-column: 4 / span 7;
  width: 17.5vw;
  height: 7.5vw;
}

.line-chart-div {
  grid-column: 4 / span 6;
  position: relative;
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .injury-title {
    grid-column: 3 / span 7;
  }
  .line-chart-div {
    margin-top: 8vw;
    grid-column: 3 / span 8;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .injury-title {
    grid-column: 3 / span 7;
  }
  .line-chart-div {
    margin-top: 5vw;
    grid-column: 3 / span 8;
  }
}
