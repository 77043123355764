@import "../../_base";

.mobile-container {
  padding-top: 4vw;
  height: 300vw;
  width: 100%;
  overflow: hidden;
}

.paragraph-mobile {
  background-color: white;
  display: grid;
  font-family: $font-family-body;
  text-align: justify;
  height: 55vh;
  font-size: 3vw;
  margin: -16.8vw 13vw 0vw 13vw;
  padding: 6vw;
  padding-top: 5vh;
}

.pdf-button {
  align-self: center;
  justify-self: center;
  background-color: $mid-blue;
  font-family: $font-family-header;
  color: white;
  text-align: center;
  font-size: 5vw;
  padding: 3vw;
  font-weight: bold;
  border-radius: 10px;
  width: 50vw;
  cursor: pointer;
}

.canadian-title-mobile {
  margin-top: -9.5vw;
  width: 123%;
  height: 90vw;
  margin-left: -11vw;
}

//contact section
.contact-mobile {
  grid-column: 3 / span 8;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  margin: 5vw 13vw 5vw 13vw;
  text-align: center;
  justify-content: center;
  padding-top: 4vw;
}

.contact-1-mobile {
  margin-top: 1vw;
  grid-column: 1 / span 4;
  justify-self: center;
  width: 80%;
}
.contact-2-mobile {
  grid-column: 1 / span 4;
  padding: 3vw;
  border-bottom: 0.3vw solid $bright-lime;
  width: 80%;
  justify-self: center;
}
.contact-3-mobile {
  grid-column: 1 / span 4;
  justify-self: center;
  border-bottom: 0.3vw solid $bright-lime;
  padding: 3vw;
  width: 80%;
}
.contact-4-mobile {
  grid-column: 1 / span 4;
  justify-self: center;
  padding: 3vw;
  width: 80%;
  display: grid;
  justify-content: center;
}

//general styling

.about-header-mobile {
  font-family: $font-family-header;
  font-weight: bold;
  color: $title-h1;
  border-bottom: 0.3vw solid $bright-lime;
  font-size: $size-h2;
}

.about-sub-header-mobile {
  color: $title-h1;
  font-family: $font-family-body;
  font-size: 2vw;
}

.about-sub-info-mobile {
  font-family: $font-family-body;
  font-weight: bold;
  font-family: $font-family-body;
  font-size: 2.5vw;
}
