@import url("https://fonts.googleapis.com/css?family=Open+Sans");

@import url("https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap");

@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");

//fonts
$font-family-body: "Muli", sans-serif;
$font-family-header: "Oswald", sans-serif;

//colors
$title-h1: #57585b;
$axis-color: #57585b;
$blue-green: #42c0ad;
$mid-blue: #8ccfc6;
$bright-lime: #bbd431;
$brighter-lime: #c9e204;
$pale-green: #f1f7d6;
$mid-green: #e4eead;
$dark-green: #909e26;
$gray-background: #f4f4f4;
$light-gray-background: #fcfcfc;

//font size
$size-text-body: 1.3vw;
$size-h2: 3.6vw;
$size-h3: 3.5vw;
$size-subheadings: 5vw;
$size-smallest: 1.05vw;
$size-chart-title: 1.6vw;

//spacing
$height-underline: 6vw;
$border-radius: 0.8vw;
$underline: 0.5vw solid $bright-lime;
$margin-left: 10vw;

//CSS grid
$grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
$grid-template-rows: repeat(6, 1fr);
