@import "../../_base";

.score-card-container {
  text-align: center;
  background-color: $light-gray-background;
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  grid-template-rows: auto;
  justify-self: center;
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.title-score-card {
  grid-area: 1 / 3 / span 1 / span 8;
  font-family: $font-family-header;
  font-weight: 900;
  text-align: left;
  font-size: $size-subheadings;
  color: $title-h1;
  border-bottom: $underline;
  width: 24.6vw;
}

.paragraph-under-bottons {
  grid-column: 3 / span 8;
  grid-row: 2 / span 1;
  margin-top: 2vw;
  font-size: 1.2vw;
  font-family: $font-family-body;
  text-align: justify;
}

//CSS for main score card:
table.main-score-card-table {
  grid-row: 3 / span 1;
  grid-column: 3 / span 8;
  margin-top: 3vw;
  margin-bottom: 2vw;
  height: 28vw;
  table-layout: fixed;
}
.toggle-view.span.right {
  margin-right: -0.1vw;
}

th.table-header {
  color: white;
  background-color: $bright-lime;
  font-family: $font-family-header;
  vertical-align: top-center;
  padding: -0.1vw;
  font-size: 1.1vw;
  font-weight: normal;
  border: 0.1vw solid white;
}

.total-header {
  color: white;
  background-color: $bright-lime;
  font-family: $font-family-header;
  vertical-align: top-center;
  border: 0.1vw solid white;
  font-size: 1vw;
}

.toggle-view {
  background-color: white;
  font-family: $font-family-header;
  font-weight: bold;
  font-size: 1.9vw;
  color: $blue-green;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: auto;
}

.toggle-view:hover {
  background-color: #8ccec4;
  color: white;
}

.toggle-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.current-policy {
  background-color: $blue-green;
  color: white;
}

.row-1 {
  height: 25%;
  vertical-align: top-center;
}

.province-header {
  font-family: $font-family-header;
  font-size: 1.2vw;
  width: 20%;
  vertical-align: top-center;
}

.td-policy {
  width: 11.3%;
}

.td-overall {
  width: 13.6%;
}

td {
  background-color: white;
  font-family: $font-family-body;
  border: 0.1vw solid $mid-green;
  font-size: $size-smallest;
}

th.pale {
  background-color: $pale-green;
}

th.mid {
  background-color: $mid-green;
}

.total-data {
  background-color: $mid-green;
  border-right: 0.1vw solid white;
  font-weight: bold;
  width: 6%;
  font-size: $size-smallest;
}

.highlight {
  background-color: $bright-lime !important;
}

//CSS for buttons:
.button-container {
  grid-row: 4 / span 1;
  grid-column: 3 / span 8;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.score-card-button {
  grid-row: 1 / span 1;
  background-color: $blue-green;
  color: white;
  font-size: 1.2vw;
  font-weight: bold;
  border-radius: $border-radius $border-radius 0 0;
  height: 6vw;
  width: 98%;
  justify-self: center;
  display: flex;
  align-items: center;
  padding: 2vw;
  font-family: $font-family-header;
  margin-top: 3vw;
}

.score-card-button.clickable:nth-of-type(2),
.score-card-button.clickable:nth-of-type(5) {
  justify-self: center;
  display: grid;
  align-self: center;
}

.clickable:hover {
  z-index: 5;
  background-color: $bright-lime !important;
  cursor: pointer;
}

//CSS for tab (pop-up) tables:
.score-card-pop-up-container {
  grid-column: 3 / span 8;
  border-radius: 0% 0% $border-radius $border-radius;
  display: grid;
  margin-left: 0.1vw;
  margin-right: -0.1vw;
  height: 18vw;
  width: 99.75%;
  position: relative;
}

.scores-table {
  height: 50%;
  width: 80%;
  margin-left: 12vw;
  table-layout: fixed;
  margin-top: auto;
  margin-bottom: auto;
  table-layout: fixed;
}

.scores-table-header {
  width: 15%;
  background-color: white;
  color: $dark-green;
  filter: grayscale(0); //no background icon showing thorugh text
  font-weight: bold;
  font-family: $font-family-header;
  text-align: right;
  font-size: 1.9vw;
  border: 0.15vw solid white;
}

.scores-table-body-1 {
  width: 5%;
  text-align: left;
  color: $dark-green;
  font-weight: bold;
  background-color: white;
  font-size: 1.9vw;
  padding-left: 0.3vw;
  border-top: 0.15vw solid white;
  border-bottom: 0.15vw solid white;
  border-right: 0.15vw solid white;
  font-family: $font-family-header;
}

.scores-table-body-2 {
  text-align: left;
  padding: 1vw;
  background: $bright-lime;
  border: 0.15vw solid white;
  font-family: $font-family-body;
}

.score-card-icon {
  width: 60%;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}

.score-td td {
  white-space: nowrap;
}

.score-tr tr {
  line-height: 5vw;
}

.highlight-green {
  background-color: $bright-lime;
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .score-card-container {
    grid-template-columns: repeat(2, 30px) repeat(8, 1fr) repeat(2, 30px);
  }
  .toggle-view {
    height: 102%; //attempt at height 100% in tablet view? for IOS
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .score-card-container {
    grid-template-columns: repeat(2, 30px) repeat(8, 1fr) repeat(2, 30px);
  }
  .toggle-view {
    height: 102%; //attempt at height 100% in tablet view? for IOS
  }
}
