@import '../../_base';

.navbar {
  grid-column: 1 / span 8 !important;
  background-color: white;
  height: 5.5vw;
  font-family: $font-family-header;
  font-size: 1.6vw;
  box-shadow: 1px 1px 20px 1px #e2e2e2;
  border-left: 104px solid transparent;
  border-right: 88px solid transparent;
}
.navbar-brand.home-link {
  font-size: 2vw !important;
  height: 4.5vw;
  margin-top: 1vw;
  padding-top: 0.4vw;
}

.home-link {
  margin-left: -0.85vw;
  -webkit-transition: opacity 200ms ease-in;
  -moz-transition: opacity 200ms ease-in;
  -o-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in;
}

.about {
  margin-left: 5vw;
  cursor: pointer;
}

.nav-space {
  background-color: $light-gray-background;
}

.navbar-expand .navbar-nav .nav-link {
  color: $bright-lime !important;
  height: 4.5vw;
  margin-top: 1vw;
  padding-top: 0.4vw;
  font-size: 2vw !important;

  &.active {
    border-bottom: 0.5vw solid $blue-green;
    color: $blue-green !important;
  }
}

.drop-down-titles {
  font-size: 1.4vw;
}

@media (min-width: 1350px) {
  .container-dividers {
    top: 10rem;
  }
}

@media (min-width: 1680px) {
  .container-dividers {
    top: 10rem;
  }
}

@media (min-width: 2200px) {
  .container-dividers {
    top: 12rem;
  }
}

@media (min-width: 2560px) {
  .container-dividers {
    top: 15rem;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .navbar {
    border-left: 15px solid transparent;
    border-right: 0px solid transparent;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .navbar {
    border-left: 43px solid transparent;
    border-right: 25px solid transparent;
  }
}
