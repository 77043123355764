@import "../../_base";
@import url("//netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");

.injury-part-2 {
  grid-row: auto;
  border: 120px solid transparent;
  display: flex;
  height: auto;
  background-color: $gray-background;
}

.container-dividers {
  grid-column: 3 / span 1;
  position: -webkit-sticky;
  position: sticky;
  top: 7rem;
  height: 30%;
  width: 50%;
  transition: 0.4s;
  margin-bottom: 7vw;
  min-height: 10vw;
}

.divider {
  font-size: $size-text-body;
  padding: 0.8vw;
  font-family: $font-family-header;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.Falls {
  border-radius: 12px 0% 0% 0%;
}

.Drowning {
  border-radius: 0% 0% 0% 12px;
}

.background-barcharts {
  min-height: 10vw; //need for smooth scroll
  width: 175%;
  border-radius: 0% 12px 12px 12px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  position: relative;
  margin-bottom: 6vw;
  transform: scaleY(1);
  transition: transform 5s ease;
  padding-bottom: 1vw;
  transition: 0.4s;
}

.long-divide {
  grid-row-start: 1;
  grid-row-end: 10;
  width: 20%;
  border-radius: 0% 0% 0% 12px;
  transition: 0.4s;
  margin-bottom: -1vw;
  min-height: 10vw;
}

.barchart-div {
  min-height: 10vw;
}
.injury-section-2-title {
  grid-column: 2 / span 4;
  align-self: center;
  font-family: $font-family-header;
  font-weight: bold;
  text-align: left;
  font-size: $size-h2;
  color: $title-h1;
  padding: 1.5vw 0vw 1vw 0;
  transition: 0.4s;
  min-height: 1vw;
}

.para1 {
  min-height: 1vw;
  grid-column: 2 / span 4;
  font-size: $size-smallest;
  font-family: $font-family-body;
  text-align: justify;
  transition: height 5s ease-in-out;
}

.bar-chart-title {
  grid-column: 2 / span 4;
  text-align: center;
  font-family: $font-family-header;
  font-size: 1.4vw;
  padding: 2vw 0vw 2vw 0;
  transition: 0.4s;
}

.barChart-div {
  grid-column: 2 / span 4;
  width: 99%;
  padding: 0vw 0vw 1.5vw 0;
}

.links-list {
  padding-left: 0;
}

.target-links,
.more-info {
  font-family: $font-family-body;
  font-size: $size-text-body;
}

.target-links {
  margin-bottom: -0.8vw;
  font-size: $size-smallest;
}

.info-link {
  color: black;
  opacity: 0.7;
}

.info-link:hover {
  color: black;
  text-decoration: underline;
  opacity: 0.6;
}

.bar-chart-fade {
  transition: background 1s;
  -webkit-transition: background 1s;
  -moz-transition: background 1s;
  -o-transition: background 1s;
  -ms-transition: background 1s;
}

//accordion
.accordion {
  grid-column: 2 / span 4;
  min-height: 1vw;
  cursor: pointer;
  padding: 0.5vw;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 1.1vw;
  font-family: $font-family-body;
  margin-bottom: 1vw;
  border-radius: 0.2vw 0.2vw 0.2vw 0.2vw !important;
}

.acc-2 {
  margin-top: -1vw;
}

.acc-1-title {
  border-radius: 0.2vw 0.2vw 0.2vw 0.2vw !important;
}

.acc-2-title {
  border-radius: 0.2vw 0.2vw 0.2vw 0.2vw !important;
}

.accordion-new:after {
  font-family: "FontAwesome";
  content: "\f078"; //down arrow
  float: right;
  color: white;
}

.unfolded.accordion-new:after {
  //need to target this correctly for active/toggle/showing cards .active .accordion-opened not working
  font-family: "FontAwesome";
  content: "\f077"; //up arrow
}

.card {
  border: none !important;
}

@media (min-width: 1350px) {
  .container-dividers {
    top: 10rem;
  }
}

@media (min-width: 2200px) {
  .container-dividers {
    top: 12rem;
  }
}

@media (min-width: 2560px) {
  .container-dividers {
    top: 15rem;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .injury-part-2 {
    border: 30px solid transparent;
  }
  .container-dividers {
    top: 3.5rem;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .injury-part-2 {
    border: 60px solid transparent;
  }
  .container-dividers {
    top: 5rem;
  }
}
