@import '../../_base';

.policy-container {
  background-image: url('/map-image/MOBILE-map.png');
  padding-top: 10vw;
  overflow: hidden;
  min-height: 100vh;
}

.privacy-policy-container {
  background-color: white;
  margin: 2rem;
  padding: 2rem;
}

.policy-header {
  font-family: $font-family-header;
  font-weight: bold;
  color: $title-h1;
  border-bottom: $underline;
  font-size: $size-h2;
}

.policy-h2 {
  color: $title-h1;
  font-family: $font-family-body;
  font-size: 2.5vw;
  font-weight: bold;
}

.policy-h3 {
  color: $title-h1;
  font-family: $font-family-body;
  font-size: 2vw;
  font-weight: bold;
}

.policy-h4 {
  color: $title-h1;
  font-family: $font-family-body;
  font-size: 1.5vw;
  font-weight: bold;
}

.policy-h5 {
  color: $title-h1;
  font-family: $font-family-body;
  font-size: 1.2vw;
  font-weight: bold;
}

.policy-para {
  font-family: $font-family-body;
  font-size: 1.2vw;
}