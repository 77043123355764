@import '../../_base';

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@import url('https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap');

.recharts-bar .recharts-bar-rectangle .recharts-rectangle:hover {
  opacity: 0.8;
}

text.recharts-text.recharts-label {
  font-weight: bold;
}

text.recharts-text.recharts-label.bar-new-label {
  font-weight: bold;
}

.bar-responsive-home {
  min-height: 25vw;
}

.graph-3 {
  min-height: 25vw;
}
