@import "../../_base";

.map {
  display: grid;
  grid-template-columns: $grid-template-columns;
  background-color: $light-gray-background;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.tooltip-container {
  position: fixed;
  transform: translateY(5%);
}

#canada-map {
  grid-column: 1 / span 14;
  width: 120%;
}

.click {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: $blue-green !important;
}

.province:hover {
  fill: $mid-blue !important;
  cursor: pointer;
  stroke: $mid-blue;
}

.highlight {
  fill: #8ccfc6 !important;
  cursor: pointer;
  stroke: #8ccfc6;
}

.tooltip {
  font-family: $font-family-header !important;
  border-radius: 0%;
  padding: 0.7vw !important;
  font-size: 1.2vw !important;
  font-weight: normal !important;
  background: $bright-lime !important;
  z-index: 14;
  opacity: 1 !important;
  line-height: 1.2vw !important;

  &.place-top {
    &:after {
      border-top-color: $bright-lime !important;
      border-top-style: solid !important;
    }
  }
}

.canada-title {
  grid-column: 5 / span 2;
  position: relative;
  width: 80vw;
  margin-top: -63vw;
}

.arrow {
  position: absolute;
  top: 47vw;
  grid-column: 1 / span 14;
  box-sizing: border-box;
  height: 8vw;
  width: 8vw;
  border-style: solid;
  border-color: $bright-lime;
  justify-self: center;
  border-width: 0vw 0.6vw 0.6vw 0vw;
  transform: rotate(45deg) translateY(8vw) translateX(8vw) skew(-30deg, -30deg);
  transition: border-width 150ms ease-in-out;
  box-shadow: 0 0 0 rgba(187, 212, 49, 1);
  animation: pulse 2s infinite ease;
  animation-delay: 5s;
}

.map-paragraph {
  grid-column: 8 / span 6;
  position: absolute;
  top: 8vw;
  right: 0;
  height: 13.2vw;
  margin-left: -6vw;
  padding: 2.5vw 1vw 1vw 2.5vw;
  font-family: $font-family-body;
  font-size: 1.3vw;
  align-content: center;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(187, 212, 49, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 0.4vw rgba(187, 212, 49, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(187, 212, 49, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(187, 212, 49, 1);
    box-shadow: 0 0 0 0 rgba(187, 212, 49, 0.9);
  }
  70% {
    -moz-box-shadow: 0.5vw 0.5vw 0vw 0.5vw rgba(187, 212, 49, 0);
    box-shadow: 0.5vw 0.5vw 0vw 0.5vw rgba(187, 212, 49, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(187, 212, 49, 0);
    box-shadow: 0 0 0 0 rgba(187, 212, 49, 0);
  }
}

@media (min-width: 700px) {
  .map-paragraph {
    padding: 2.5vw 1vw 1vw 9.5vw;
  }
}

@media (min-width: 992px) {
  .map-paragraph {
    padding: 2.5vw 1vw 1vw 8vw;
  }
}

@media (min-width: 1025px) {
  .map-paragraph {
    padding: 2.5vw 1vw 1vw 7vw;
  }
}

@media (min-width: 1350px) {
  .map-paragraph {
    padding: 2.5vw 1vw 1vw 6vw;
  }
}

@media (min-width: 1680px) {
  .map-paragraph {
    padding: 2.5vw 1vw 1vw 5vw;
  }
}

//tablet portrait
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .map {
    margin-top: 5vw;
  }
}

//tablet landscape
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .map {
    margin-top: 2vw;
  }
}
