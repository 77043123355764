@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(//netdna.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Oswald|Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  align-items: center;
  background-color: #fcfcfc;
  scroll-behavior: smooth; }

.map {
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  background-color: #fcfcfc;
  position: relative;
  overflow: hidden;
  height: 100%; }

.tooltip-container {
  position: fixed;
  -webkit-transform: translateY(5%);
          transform: translateY(5%); }

#canada-map {
  grid-column: 1 / span 14;
  width: 120%; }

.click {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #42c0ad !important; }

.province:hover {
  fill: #8ccfc6 !important;
  cursor: pointer;
  stroke: #8ccfc6; }

.highlight {
  fill: #8ccfc6 !important;
  cursor: pointer;
  stroke: #8ccfc6; }

.tooltip {
  font-family: "Oswald", sans-serif !important;
  border-radius: 0%;
  padding: 0.7vw !important;
  font-size: 1.2vw !important;
  font-weight: normal !important;
  background: #bbd431 !important;
  z-index: 14;
  opacity: 1 !important;
  line-height: 1.2vw !important; }
  .tooltip.place-top:after {
    border-top-color: #bbd431 !important;
    border-top-style: solid !important; }

.canada-title {
  grid-column: 5 / span 2;
  position: relative;
  width: 80vw;
  margin-top: -63vw; }

.arrow {
  position: absolute;
  top: 47vw;
  grid-column: 1 / span 14;
  box-sizing: border-box;
  height: 8vw;
  width: 8vw;
  border-style: solid;
  border-color: #bbd431;
  justify-self: center;
  border-width: 0vw 0.6vw 0.6vw 0vw;
  -webkit-transform: rotate(45deg) translateY(8vw) translateX(8vw) skew(-30deg, -30deg);
          transform: rotate(45deg) translateY(8vw) translateX(8vw) skew(-30deg, -30deg);
  -webkit-transition: border-width 150ms ease-in-out;
  transition: border-width 150ms ease-in-out;
  box-shadow: 0 0 0 #bbd431;
  -webkit-animation: pulse 2s infinite ease;
          animation: pulse 2s infinite ease;
  -webkit-animation-delay: 5s;
          animation-delay: 5s; }

.map-paragraph {
  grid-column: 8 / span 6;
  position: absolute;
  top: 8vw;
  right: 0;
  height: 13.2vw;
  margin-left: -6vw;
  padding: 2.5vw 1vw 1vw 2.5vw;
  font-family: "Muli", sans-serif;
  font-size: 1.3vw;
  align-content: center; }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(187, 212, 49, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 0.4vw rgba(187, 212, 49, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(187, 212, 49, 0); } }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(187, 212, 49, 0.9); }
  70% {
    box-shadow: 0.5vw 0.5vw 0vw 0.5vw rgba(187, 212, 49, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(187, 212, 49, 0); } }

@media (min-width: 700px) {
  .map-paragraph {
    padding: 2.5vw 1vw 1vw 9.5vw; } }

@media (min-width: 992px) {
  .map-paragraph {
    padding: 2.5vw 1vw 1vw 8vw; } }

@media (min-width: 1025px) {
  .map-paragraph {
    padding: 2.5vw 1vw 1vw 7vw; } }

@media (min-width: 1350px) {
  .map-paragraph {
    padding: 2.5vw 1vw 1vw 6vw; } }

@media (min-width: 1680px) {
  .map-paragraph {
    padding: 2.5vw 1vw 1vw 5vw; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .map {
    margin-top: 5vw; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .map {
    margin-top: 2vw; } }

.whole-home-intro {
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  grid-template-rows: auto;
  overflow: visible;
  margin-top: 5vw; }

.para-after {
  grid-column: 4 / span 6;
  font-size: 1.3vw;
  font-family: "Muli", sans-serif;
  text-align: left;
  text-align: justify;
  margin-top: 4vw;
  justify-self: center; }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .whole-home-intro {
    margin-top: 8vw;
    grid-template-columns: repeat(2, 15px) repeat(8, 1fr) repeat(2, 15px); }
  .bar-chart-intro-title {
    margin-right: -8vw; }
  .barchart-home-intro {
    margin-left: -6vw; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .bar-chart-intro-title {
    margin-right: -9vw; }
  .bar-chart-intro-title {
    margin-bottom: -21vw;
    margin-right: -8vw; }
  .whole-home-intro {
    margin-top: 7vw;
    grid-template-columns: repeat(2, 30px) repeat(8, 1fr) repeat(2, 30px); }
  .barchart-home-intro {
    margin-left: -5vw; } }

.bar-chart-intro-title {
  padding-bottom: 4vw;
  color: #57585b;
  grid-column: 4 / span 6;
  font-family: "Oswald", sans-serif;
  font-size: 1.3vw;
  text-align: center;
  margin-bottom: -1vw; }

.barchart-home-intro {
  grid-column: 4 / span 6;
  margin-bottom: 2vw;
  align-self: center; }

.recharts-bar .recharts-bar-rectangle .recharts-rectangle:hover {
  opacity: 0.8; }

text.recharts-text.recharts-label {
  font-weight: bold; }

text.recharts-text.recharts-label.bar-new-label {
  font-weight: bold; }

.bar-responsive-home {
  min-height: 25vw; }

.graph-3 {
  min-height: 25vw; }

.linechart-background {
  min-height: 60vw;
  margin-top: 4vw;
  overflow: hidden;
  padding-top: 2vw;
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  grid-template-rows: auto;
  margin-top: 5vw;
  transition: background 4s ease;
  -webkit-transition: background 4s ease;
  -moz-transition: background 4s ease;
  -o-transition: background 4s ease;
  -ms-transition: background 4s ease; }

.injury-title {
  color: #57585b;
  font-family: "Oswald", sans-serif;
  font-size: 5vw;
  font-weight: bold;
  line-height: 1.5;
  background-color: #f4f4f4;
  text-align: left;
  align-items: left;
  border-bottom: 0.5vw solid #bbd431; }

.injury-title {
  grid-column: 4 / span 7;
  width: 17.5vw;
  height: 7.5vw; }

.line-chart-div {
  grid-column: 4 / span 6;
  position: relative; }

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .injury-title {
    grid-column: 3 / span 7; }
  .line-chart-div {
    margin-top: 8vw;
    grid-column: 3 / span 8; } }

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .injury-title {
    grid-column: 3 / span 7; }
  .line-chart-div {
    margin-top: 5vw;
    grid-column: 3 / span 8; } }

.label {
  font-family: "Oswald", sans-serif; }

.bc-hospitalization-graph {
  min-height: 40vw; }

text.recharts-text.recharts-label {
  font-weight: normal; }

.title-hospitalization-graph {
  text-align: center;
  position: relative;
  margin-top: -4vw;
  margin-right: 7vw;
  margin-bottom: 2vw;
  font-family: "Oswald", sans-serif;
  padding: 2vw;
  font-size: 1.6vw;
  font-weight: bold; }

.recharts-yAxis
.recharts-cartesian-axis-ticks
.recharts-cartesian-axis-tick:first-child
line {
  stroke: none; }

.recharts-yAxis
.recharts-cartesian-axis-ticks
.recharts-cartesian-axis-tick:first-child
text {
  font-size: 0; }

.recharts-cartesian-axis-tick {
  font-family: "Muli", sans-serif; }

.legend-item {
  font-family: "Muli", sans-serif; }

div.recharts-legend-wrapper {
  position: relative;
  left: 1020 !important; }

.y-axis-responsive {
  font-size: 1vw;
  font-family: "Oswald", sans-serif;
  -webkit-transform: rotate(-90deg);
  /* Safari */
  -moz-transform: rotate(-90deg);
  /* Firefox */
  -ms-transform: rotate(-90deg);
  /* IE */
  -o-transform: rotate(-90deg);
  /* Opera */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  /* Internet Explorer */
  position: absolute;
  top: 35%;
  left: -2vw; }

.injury-part-2 {
  grid-row: auto;
  border: 120px solid transparent;
  display: flex;
  height: auto;
  background-color: #f4f4f4; }

.container-dividers {
  grid-column: 3 / span 1;
  position: -webkit-sticky;
  position: sticky;
  top: 7rem;
  height: 30%;
  width: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: 7vw;
  min-height: 10vw; }

.divider {
  font-size: 1.3vw;
  padding: 0.8vw;
  font-family: "Oswald", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  cursor: pointer; }

.Falls {
  border-radius: 12px 0% 0% 0%; }

.Drowning {
  border-radius: 0% 0% 0% 12px; }

.background-barcharts {
  min-height: 10vw;
  width: 175%;
  border-radius: 0% 12px 12px 12px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  position: relative;
  margin-bottom: 6vw;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  -webkit-transition: -webkit-transform 5s ease;
  transition: -webkit-transform 5s ease;
  transition: transform 5s ease;
  transition: transform 5s ease, -webkit-transform 5s ease;
  padding-bottom: 1vw;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.long-divide {
  grid-row-start: 1;
  grid-row-end: 10;
  width: 20%;
  border-radius: 0% 0% 0% 12px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-bottom: -1vw;
  min-height: 10vw; }

.barchart-div {
  min-height: 10vw; }

.injury-section-2-title {
  grid-column: 2 / span 4;
  align-self: center;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  text-align: left;
  font-size: 3.6vw;
  color: #57585b;
  padding: 1.5vw 0vw 1vw 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  min-height: 1vw; }

.para1 {
  min-height: 1vw;
  grid-column: 2 / span 4;
  font-size: 1.05vw;
  font-family: "Muli", sans-serif;
  text-align: justify;
  -webkit-transition: height 5s ease-in-out;
  transition: height 5s ease-in-out; }

.bar-chart-title {
  grid-column: 2 / span 4;
  text-align: center;
  font-family: "Oswald", sans-serif;
  font-size: 1.4vw;
  padding: 2vw 0vw 2vw 0;
  -webkit-transition: 0.4s;
  transition: 0.4s; }

.barChart-div {
  grid-column: 2 / span 4;
  width: 99%;
  padding: 0vw 0vw 1.5vw 0; }

.links-list {
  padding-left: 0; }

.target-links,
.more-info {
  font-family: "Muli", sans-serif;
  font-size: 1.3vw; }

.target-links {
  margin-bottom: -0.8vw;
  font-size: 1.05vw; }

.info-link {
  color: black;
  opacity: 0.7; }

.info-link:hover {
  color: black;
  text-decoration: underline;
  opacity: 0.6; }

.bar-chart-fade {
  transition: background 1s;
  -webkit-transition: background 1s;
  -moz-transition: background 1s;
  -o-transition: background 1s;
  -ms-transition: background 1s; }

.accordion {
  grid-column: 2 / span 4;
  min-height: 1vw;
  cursor: pointer;
  padding: 0.5vw;
  width: 100%;
  text-align: left;
  outline: none;
  font-size: 1.1vw;
  font-family: "Muli", sans-serif;
  margin-bottom: 1vw;
  border-radius: 0.2vw 0.2vw 0.2vw 0.2vw !important; }

.acc-2 {
  margin-top: -1vw; }

.acc-1-title {
  border-radius: 0.2vw 0.2vw 0.2vw 0.2vw !important; }

.acc-2-title {
  border-radius: 0.2vw 0.2vw 0.2vw 0.2vw !important; }

.accordion-new:after {
  font-family: "FontAwesome";
  content: "\f078";
  float: right;
  color: white; }

.unfolded.accordion-new:after {
  font-family: "FontAwesome";
  content: "\f077"; }

.card {
  border: none !important; }

@media (min-width: 1350px) {
  .container-dividers {
    top: 10rem; } }

@media (min-width: 2200px) {
  .container-dividers {
    top: 12rem; } }

@media (min-width: 2560px) {
  .container-dividers {
    top: 15rem; } }

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .injury-part-2 {
    border: 30px solid transparent; }
  .container-dividers {
    top: 3.5rem; } }

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .injury-part-2 {
    border: 60px solid transparent; }
  .container-dividers {
    top: 5rem; } }

.score-card-container {
  text-align: center;
  background-color: #fcfcfc;
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  grid-template-rows: auto;
  justify-self: center;
  padding-top: 2vw;
  padding-bottom: 2vw; }

.title-score-card {
  grid-area: 1 / 3 / span 1 / span 8;
  font-family: "Oswald", sans-serif;
  font-weight: 900;
  text-align: left;
  font-size: 5vw;
  color: #57585b;
  border-bottom: 0.5vw solid #bbd431;
  width: 24.6vw; }

.paragraph-under-bottons {
  grid-column: 3 / span 8;
  grid-row: 2 / span 1;
  margin-top: 2vw;
  font-size: 1.2vw;
  font-family: "Muli", sans-serif;
  text-align: justify; }

table.main-score-card-table {
  grid-row: 3 / span 1;
  grid-column: 3 / span 8;
  margin-top: 3vw;
  margin-bottom: 2vw;
  height: 28vw;
  table-layout: fixed; }

.toggle-view.span.right {
  margin-right: -0.1vw; }

th.table-header {
  color: white;
  background-color: #bbd431;
  font-family: "Oswald", sans-serif;
  vertical-align: top-center;
  padding: -0.1vw;
  font-size: 1.1vw;
  font-weight: normal;
  border: 0.1vw solid white; }

.total-header {
  color: white;
  background-color: #bbd431;
  font-family: "Oswald", sans-serif;
  vertical-align: top-center;
  border: 0.1vw solid white;
  font-size: 1vw; }

.toggle-view {
  background-color: white;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 1.9vw;
  color: #42c0ad;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: auto; }

.toggle-view:hover {
  background-color: #8ccec4;
  color: white; }

.toggle-container {
  display: flex;
  height: 100%;
  width: 100%; }

.current-policy {
  background-color: #42c0ad;
  color: white; }

.row-1 {
  height: 25%;
  vertical-align: top-center; }

.province-header {
  font-family: "Oswald", sans-serif;
  font-size: 1.2vw;
  width: 20%;
  vertical-align: top-center; }

.td-policy {
  width: 11.3%; }

.td-overall {
  width: 13.6%; }

td {
  background-color: white;
  font-family: "Muli", sans-serif;
  border: 0.1vw solid #e4eead;
  font-size: 1.05vw; }

th.pale {
  background-color: #f1f7d6; }

th.mid {
  background-color: #e4eead; }

.total-data {
  background-color: #e4eead;
  border-right: 0.1vw solid white;
  font-weight: bold;
  width: 6%;
  font-size: 1.05vw; }

.highlight {
  background-color: #bbd431 !important; }

.button-container {
  grid-row: 4 / span 1;
  grid-column: 3 / span 8;
  display: grid;
  grid-template-columns: repeat(6, 1fr); }

.score-card-button {
  grid-row: 1 / span 1;
  background-color: #42c0ad;
  color: white;
  font-size: 1.2vw;
  font-weight: bold;
  border-radius: 0.8vw 0.8vw 0 0;
  height: 6vw;
  width: 98%;
  justify-self: center;
  display: flex;
  align-items: center;
  padding: 2vw;
  font-family: "Oswald", sans-serif;
  margin-top: 3vw; }

.score-card-button.clickable:nth-of-type(2),
.score-card-button.clickable:nth-of-type(5) {
  justify-self: center;
  display: grid;
  align-self: center; }

.clickable:hover {
  z-index: 5;
  background-color: #bbd431 !important;
  cursor: pointer; }

.score-card-pop-up-container {
  grid-column: 3 / span 8;
  border-radius: 0% 0% 0.8vw 0.8vw;
  display: grid;
  margin-left: 0.1vw;
  margin-right: -0.1vw;
  height: 18vw;
  width: 99.75%;
  position: relative; }

.scores-table {
  height: 50%;
  width: 80%;
  margin-left: 12vw;
  table-layout: fixed;
  margin-top: auto;
  margin-bottom: auto;
  table-layout: fixed; }

.scores-table-header {
  width: 15%;
  background-color: white;
  color: #909e26;
  -webkit-filter: grayscale(0);
          filter: grayscale(0);
  font-weight: bold;
  font-family: "Oswald", sans-serif;
  text-align: right;
  font-size: 1.9vw;
  border: 0.15vw solid white; }

.scores-table-body-1 {
  width: 5%;
  text-align: left;
  color: #909e26;
  font-weight: bold;
  background-color: white;
  font-size: 1.9vw;
  padding-left: 0.3vw;
  border-top: 0.15vw solid white;
  border-bottom: 0.15vw solid white;
  border-right: 0.15vw solid white;
  font-family: "Oswald", sans-serif; }

.scores-table-body-2 {
  text-align: left;
  padding: 1vw;
  background: #bbd431;
  border: 0.15vw solid white;
  font-family: "Muli", sans-serif; }

.score-card-icon {
  width: 60%;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0; }

.score-td td {
  white-space: nowrap; }

.score-tr tr {
  line-height: 5vw; }

.highlight-green {
  background-color: #bbd431; }

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .score-card-container {
    grid-template-columns: repeat(2, 30px) repeat(8, 1fr) repeat(2, 30px); }
  .toggle-view {
    height: 102%; } }

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .score-card-container {
    grid-template-columns: repeat(2, 30px) repeat(8, 1fr) repeat(2, 30px); }
  .toggle-view {
    height: 102%; } }

.highlight {
  background-color: #bbd431; }


.mobile-container {
  padding-top: 4vw;
  height: 300vw;
  width: 100%;
  overflow: hidden; }

.paragraph-mobile {
  background-color: white;
  display: grid;
  font-family: "Muli", sans-serif;
  text-align: justify;
  height: 55vh;
  font-size: 3vw;
  margin: -16.8vw 13vw 0vw 13vw;
  padding: 6vw;
  padding-top: 5vh; }

.pdf-button {
  align-self: center;
  justify-self: center;
  background-color: #8ccfc6;
  font-family: "Oswald", sans-serif;
  color: white;
  text-align: center;
  font-size: 5vw;
  padding: 3vw;
  font-weight: bold;
  border-radius: 10px;
  width: 50vw;
  cursor: pointer; }

.canadian-title-mobile {
  margin-top: -9.5vw;
  width: 123%;
  height: 90vw;
  margin-left: -11vw; }

.contact-mobile {
  grid-column: 3 / span 8;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  margin: 5vw 13vw 5vw 13vw;
  text-align: center;
  justify-content: center;
  padding-top: 4vw; }

.contact-1-mobile {
  margin-top: 1vw;
  grid-column: 1 / span 4;
  justify-self: center;
  width: 80%; }

.contact-2-mobile {
  grid-column: 1 / span 4;
  padding: 3vw;
  border-bottom: 0.3vw solid #bbd431;
  width: 80%;
  justify-self: center; }

.contact-3-mobile {
  grid-column: 1 / span 4;
  justify-self: center;
  border-bottom: 0.3vw solid #bbd431;
  padding: 3vw;
  width: 80%; }

.contact-4-mobile {
  grid-column: 1 / span 4;
  justify-self: center;
  padding: 3vw;
  width: 80%;
  display: grid;
  justify-content: center; }

.about-header-mobile {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  color: #57585b;
  border-bottom: 0.3vw solid #bbd431;
  font-size: 3.6vw; }

.about-sub-header-mobile {
  color: #57585b;
  font-family: "Muli", sans-serif;
  font-size: 2vw; }

.about-sub-info-mobile {
  font-family: "Muli", sans-serif;
  font-weight: bold;
  font-family: "Muli", sans-serif;
  font-size: 2.5vw; }

.navbar {
  grid-column: 1 / span 8 !important;
  background-color: white;
  height: 5.5vw;
  font-family: "Oswald", sans-serif;
  font-size: 1.6vw;
  box-shadow: 1px 1px 20px 1px #e2e2e2;
  border-left: 104px solid transparent;
  border-right: 88px solid transparent; }

.navbar-brand.home-link {
  font-size: 2vw !important;
  height: 4.5vw;
  margin-top: 1vw;
  padding-top: 0.4vw; }

.home-link {
  margin-left: -0.85vw;
  -webkit-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in; }

.about {
  margin-left: 5vw;
  cursor: pointer; }

.nav-space {
  background-color: #fcfcfc; }

.navbar-expand .navbar-nav .nav-link {
  color: #bbd431 !important;
  height: 4.5vw;
  margin-top: 1vw;
  padding-top: 0.4vw;
  font-size: 2vw !important; }
  .navbar-expand .navbar-nav .nav-link.active {
    border-bottom: 0.5vw solid #42c0ad;
    color: #42c0ad !important; }

.drop-down-titles {
  font-size: 1.4vw; }

@media (min-width: 1350px) {
  .container-dividers {
    top: 10rem; } }

@media (min-width: 1680px) {
  .container-dividers {
    top: 10rem; } }

@media (min-width: 2200px) {
  .container-dividers {
    top: 12rem; } }

@media (min-width: 2560px) {
  .container-dividers {
    top: 15rem; } }

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .navbar {
    border-left: 15px solid transparent;
    border-right: 0px solid transparent; } }

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .navbar {
    border-left: 43px solid transparent;
    border-right: 25px solid transparent; } }

.footer {
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  background: #8ccfc6;
  height: 6vw;
  align-content: center; }

.contact-link {
  grid-column: 3 / span 4;
  color: white;
  text-decoration: underline;
  font-family: "Muli", sans-serif;
  font-size: 1vw;
  cursor: pointer; }

.contact-link:hover {
  color: #3e7cb1; }

.copyright {
  grid-column: 7 / span 4;
  color: white;
  font-family: "Muli", sans-serif;
  text-align: right;
  font-size: 1vw; }

.copyright-icon {
  color: white; }

.timeline-div {
  width: 100%; }

.recharts-surface {
  overflow: visible; }

.timeline-container {
  background-color: #fcfcfc;
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  grid-template-rows: auto;
  margin-top: 5vw;
  min-height: 10vw; }

.title-timeline {
  grid-column: 4 / span 6;
  height: 7.5vw;
  width: 17.5vw;
  color: #57585b;
  font-family: "Oswald", sans-serif;
  font-size: 5vw;
  font-weight: 900;
  text-align: left;
  border-bottom: 0.5vw solid #bbd431; }

.scatter-chart.fade-in {
  grid-column: 5 / span 5;
  margin-left: -6.66vw;
  min-height: 10vw; }

p.timeline-para {
  grid-column: 5 / span 4;
  text-align: justify;
  align-self: start;
  font-size: 1.3vw;
  font-family: "Muli", sans-serif;
  margin-top: 4vw;
  margin-left: -5vw;
  margin-right: -5vw; }

.recharts-text.recharts-label.label-list {
  font-family: "Oswald", sans-serif;
  background-color: #42c0ad;
  border-radius: 0.8vw;
  font-weight: bold; }

.timeline-pop-up-container {
  grid-column: 4 / span 6;
  height: 18.5vw;
  border-radius: 0.8vw;
  position: relative; }

.icon-pop-up {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0; }

.timeline-table {
  width: 50vw;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 55%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.timeline-table-top-header {
  text-align: center;
  background-color: white;
  color: #909e26;
  font-weight: bold;
  font-size: 1.3vw;
  font-family: "Oswald", sans-serif;
  border: 0.1vw solid white; }

.timeline-table-left-header {
  width: 30%;
  opacity: 0.6;
  color: #2c300a;
  background-color: white;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
  text-align: right;
  font-size: 1.05vw;
  padding-right: 1vw;
  border: 0.1vw solid white;
  text-align: center; }

.timeline-table-body {
  width: 35%;
  text-align: center;
  font-weight: bold;
  padding-left: 1vw;
  background-color: #bbd431;
  border: 0.1vw solid white;
  font-family: "Muli", sans-serif;
  font-size: 0.8vw; }

rect.move-it {
  vertical-align: top; }

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .title-timeline {
    grid-column: 3 / span 8; }
  .scatter-chart.fade-in {
    grid-column: 3 / span 8;
    margin-left: -3.35vw;
    margin-top: -30vw; }
  p.timeline-para {
    grid-column: 4 / span 6; }
  .timeline-pop-up-container {
    grid-column: 3 / span 8; }
  .timeline-table-body {
    width: 35%;
    font-size: 0.7vw; }
  .timeline-table {
    width: 60vw; } }

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .title-timeline {
    grid-column: 3 / span 8; }
  .scatter-chart.fade-in {
    grid-column: 3 / span 8;
    margin-left: -3vw;
    margin-top: -30vw; }
  p.timeline-para {
    grid-column: 4 / span 6; }
  .timeline-pop-up-container {
    grid-column: 3 / span 8; }
  .timeline-table {
    width: 65vw; } }

@media (min-device-width: 2200px) {
  .scatter-chart.fade-in {
    margin-top: -1vw; } }

.outer-container {
  margin-top: 5vw;
  padding-top: 6vw;
  background-color: #fcfcfc;
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  grid-template-rows: repeat(3, fr);
  padding-bottom: 2vw;
  height: 55vw; }

.prov-title {
  grid-area: 1 / 7 / span 1 / span 3;
  background-repeat: no-repeat;
  min-height: 20vw;
  margin-left: -0.6vw;
  width: 101.5%;
  align-self: end; }

.intro-para {
  grid-area: 2 / 7 / span 1 / span 3;
  font-family: "Muli", sans-serif;
  font-size: 1.3vw;
  font-weight: 500;
  line-height: 1.4em;
  text-align: justify;
  margin-left: -6.2vw; }

.province-svg {
  grid-area: 1 / 4 / span 2 / span 3;
  height: 100%;
  background-repeat: no-repeat; }

.infrographic-section {
  margin-top: 2vw;
  min-height: 35vw;
  margin-left: -1.5vw;
  width: 150%;
  align-self: end;
  background-repeat: no-repeat; }

.infro1 {
  grid-area: 3 / 4 / span 1 / span 2; }

.container-right {
  grid-area: 1 / 7 / span 2 / span 6;
  padding: 1vw; }

.outer-container {
  width: 100%; }

@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue; } }

.info-container {
  background-color: #fcfcfc;
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  height: 100%;
  overflow: hidden; }

.infrographic-section {
  background-repeat: no-repeat;
  grid-column: 4 / span 6;
  width: 100%;
  margin-left: 1vw; }

@media (min-width: 1024px) {
  .info-container {
    margin-top: 5vw; }
  .infrographic-section {
    margin-left: -5vw;
    width: 120%;
    height: 103.5%;
    margin-top: 3vw; } }

@media (min-width: 1200px) {
  .info-container {
    margin-top: 10vw; }
  .infrographic-section {
    margin-left: -8vw;
    width: 130%;
    height: 100%;
    margin-top: 9vw; } }

@media (min-width: 1440px) {
  .info-container {
    margin-top: 15vw; }
  .infrographic-section {
    margin-left: -10vw;
    width: 130%;
    height: 100%;
    margin-top: 11vw; } }

@media (min-width: 2011px) {
  .info-container {
    margin-top: 20vw; }
  .infrographic-section {
    margin-left: -10vw;
    width: 130%;
    height: 100%;
    margin-top: 13vw; } }

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .info-container {
    grid-template-columns: repeat(2, 30px) repeat(8, 1fr) repeat(2, 30px); }
  .infrographic-section {
    margin-left: -5vw;
    width: 120%;
    height: 95%;
    margin-top: 10vw; } }

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .info-container {
    grid-template-columns: repeat(2, 30px) repeat(8, 1fr) repeat(2, 30px); }
  .infrographic-section {
    height: 103%;
    margin-top: 5vw;
    width: 115%;
    margin-left: -5vw; } }

@charset "UTF-8";
.about-container {
  background-image: url("/map-image/MOBILE-map.png");
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  grid-template-rows: auto;
  padding-top: 10vw;
  overflow: hidden;
  min-height: 100vh; }

.publications {
  grid-column: 3 / span 4;
  background-color: white;
  margin: 2vw;
  justify-self: center;
  display: grid;
  margin-bottom: 5vw;
  padding: 3vw; }

.rel-container {
  display: block; }

.rel {
  display: inline-block;
  text-align: justify;
  height: 4.7vw;
  margin-bottom: 4vh; }

.about-list {
  list-style-type: none !important;
  justify-self: left;
  padding-left: 0vw; }

.about-link {
  color: #42c0ad;
  text-decoration: underline;
  font-family: "Muli", sans-serif;
  font-size: 1.3vw !important;
  cursor: pointer; }

.about-link:hover {
  color: #bbd431; }

.acknowledgments {
  grid-column: 7 / span 4;
  background-color: white;
  margin: 2vw;
  justify-self: stretch;
  display: grid;
  margin-bottom: 5vw;
  padding: 3vw; }

.ack-container {
  display: block; }

.ack {
  display: inline-block;
  text-align: justify;
  margin-bottom: 4vh;
  letter-spacing: 0.06vw; }

.about-para {
  font-family: "Muli", sans-serif;
  font-size: 1.4vw; }

.logos-container {
  grid-row: 3;
  display: grid;
  grid-template-columns: repeat 5, 1fr;
  overflow: visible; }

.logo-bcch {
  height: 4vw;
  width: 100%;
  margin-left: 5vw;
  margin-top: 2.6vw;
  grid-column: 1 / span 1; }

.logo-cihr {
  margin-left: 0.5vw;
  height: 8vw;
  width: 120%;
  grid-column: 2 / span 4;
  -webkit-transform: scale(0.4);
  /* Saf3.1+, Chrome */
  /* FF3.5+ */
  /* IE9 */
  /* Opera 10.5+ */
  transform: scale(0.4);
  /* IE6–IE9 */
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713,SizingMethod='auto expand'); }

.contact {
  grid-column: 3 / span 8;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  display: grid;
  margin-bottom: 5vw; }

.contact-1-container {
  grid-column: 1 / span 1;
  margin-top: 2vw;
  margin-left: 3vw; }

.contact-2 {
  grid-column: 2 / span 1;
  align-self: center;
  padding: 3vw; }

.contact-3 {
  grid-column: 3 / span 1;
  align-self: center;
  border-left: 0.3vw solid #bbd431;
  padding: 3vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
  display: grid;
  align-content: center; }

.contact-4 {
  grid-column: 4 / span 1;
  align-self: center;
  border-left: 0.3vw solid #bbd431;
  padding: 3vw;
  margin-top: 2vw;
  margin-bottom: 2vw;
  display: grid;
  align-content: center; }

.about-header {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  color: #57585b;
  border-bottom: 0.5vw solid #bbd431;
  font-size: 3.6vw; }

.about-sub-header {
  color: #57585b;
  font-family: "Muli", sans-serif;
  font-size: 0.8vw; }

.about-sub-info {
  font-family: "Muli", sans-serif;
  font-weight: bold;
  font-family: "Muli", sans-serif;
  font-size: 1.3vw; }

@media (max-width: 1025px) {
  .about-container {
    grid-template-columns: repeat(2, 30px) repeat(8, 1fr) repeat(2, 30px); }
  .logo-cihr {
    margin-left: -13vw;
    height: 17vw;
    width: 230%;
    -webkit-transform: scale(0.2);
    /* Saf3.1+, Chrome */
    /* FF3.5+ */
    /* IE9 */
    /* Opera 10.5+ */
    transform: scale(0.2); }
  .logo-bcch {
    margin-top: 6.7vw;
    margin-left: 4vw; } }

@media (max-width: 768px) {
  .about-container {
    grid-template-columns: repeat(2, 15px) repeat(8, 1fr) repeat(2, 15px);
    margin-top: 5vw;
    height: 100vh; }
  .publications {
    margin-top: 2vw; }
  .acknowledgments {
    margin-top: 2vw; }
  .contact {
    margin-top: 8vw;
    margin-bottom: 9vw; }
  .about-para {
    font-size: 1.6vw; }
  .contact {
    height: 20vh; }
  .logo-cihr {
    margin-left: -17vw;
    height: 17vw;
    width: 230%;
    -webkit-transform: scale(0.2);
    /* Saf3.1+, Chrome */
    /* FF3.5+ */
    /* IE9 */
    /* Opera 10.5+ */
    transform: scale(0.2); }
  .logo-bcch {
    margin-top: 6.7vw;
    margin-left: 4.2vw; } }

@media (min-width: 1025px) {
  .publications {
    padding: 1.5vw; }
  .acknowledgments {
    padding: 1.5vw; }
  .contact-1-container {
    grid-column: 1 / span 1;
    margin-top: 2vw;
    margin-left: 1.5vw; }
  .logo-cihr {
    margin-left: -12vw;
    height: 13vw;
    width: 230%;
    -webkit-transform: scale(0.24);
    /* Saf3.1+, Chrome */
    /* FF3.5+ */
    /* IE9 */
    /* Opera 10.5+ */
    transform: scale(0.24); }
  .logo-bcch {
    margin-top: 4.7vw;
    margin-left: 4.2vw; } }

@media (min-width: 1350px) {
  .publications {
    padding: 2.8vw; }
  .acknowledgments {
    padding: 2.8vw; }
  .logo-cihr {
    margin-left: -9vw;
    -webkit-transform: scale(0.35);
    /* Saf3.1+, Chrome */
    /* FF3.5+ */
    /* IE9 */
    /* Opera 10.5+ */
    transform: scale(0.35); }
  .logo-bcch {
    margin-top: 3.4vw;
    margin-left: 3.2vw; } }

@media (min-width: 1680px) {
  .publications {
    padding: 3vw; }
  .acknowledgments {
    padding: 3vw; }
  .logo-cihr {
    margin-left: -7vw;
    -webkit-transform: scale(0.45);
    /* Saf3.1+, Chrome */
    /* FF3.5+ */
    /* IE9 */
    /* Opera 10.5+ */
    transform: scale(0.45); }
  .logo-bcch {
    margin-top: 3.5vw;
    margin-left: 3vw; } }

@media (min-width: 2200px) {
  .logo-cihr {
    margin-left: -2vw;
    -webkit-transform: scale(0.65);
    /* Saf3.1+, Chrome */
    /* FF3.5+ */
    /* IE9 */
    /* Opera 10.5+ */
    transform: scale(0.6); }
  .logo-bcch {
    margin-top: 3vw;
    margin-left: 2.7vw; } }

@media (min-width: 2560px) {
  .logo-bcch {
    margin-top: 3vw;
    margin-left: 2.7vw; } }

.policy-container {
  background-image: url("/map-image/MOBILE-map.png");
  padding-top: 10vw;
  overflow: hidden;
  min-height: 100vh; }

.privacy-policy-container {
  background-color: white;
  margin: 2rem;
  padding: 2rem; }

.policy-header {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  color: #57585b;
  border-bottom: 0.5vw solid #bbd431;
  font-size: 3.6vw; }

.policy-h2 {
  color: #57585b;
  font-family: "Muli", sans-serif;
  font-size: 2.5vw;
  font-weight: bold; }

.policy-h3 {
  color: #57585b;
  font-family: "Muli", sans-serif;
  font-size: 2vw;
  font-weight: bold; }

.policy-h4 {
  color: #57585b;
  font-family: "Muli", sans-serif;
  font-size: 1.5vw;
  font-weight: bold; }

.policy-h5 {
  color: #57585b;
  font-family: "Muli", sans-serif;
  font-size: 1.2vw;
  font-weight: bold; }

.policy-para {
  font-family: "Muli", sans-serif;
  font-size: 1.2vw; }

