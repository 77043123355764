@import "../../_base";

.recharts-surface {
  overflow: visible; //needed timeline chart
}

.timeline-container {
  background-color: $light-gray-background;
  display: grid;
  grid-template-columns: repeat(2, 60px) repeat(8, 1fr) repeat(2, 60px);
  grid-template-rows: auto;
  margin-top: 5vw;
  min-height: 10vw;
}

.title-timeline {
  grid-column: 4 / span 6;
  height: 7.5vw;
  width: 17.5vw;
  color: $title-h1;
  font-family: $font-family-header;
  font-size: $size-subheadings;
  font-weight: 900;
  text-align: left;
  border-bottom: $underline;
}

.scatter-chart.fade-in {
  grid-column: 5 / span 5;
  margin-left: -6.66vw;
  min-height: 10vw;
}

p.timeline-para {
  grid-column: 5 / span 4;
  text-align: justify;
  align-self: start;
  font-size: $size-text-body;
  font-family: $font-family-body;
  margin-top: 4vw;
  margin-left: -5vw;
  margin-right: -5vw;
}

.recharts-text.recharts-label.label-list {
  font-family: $font-family-header;
  background-color: $blue-green;
  border-radius: $border-radius;
  font-weight: bold;
}

.timeline-pop-up-container {
  grid-column: 4 / span 6;
  height: 18.5vw;
  border-radius: $border-radius;
  position: relative;
}

//CSS for pop-up
.icon-pop-up {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}

.timeline-table {
  width: 50vw;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}

// CSS for table within pop-up
.timeline-table-top-header {
  text-align: center;
  background-color: white;
  color: $dark-green;
  font-weight: bold;
  font-size: $size-text-body;
  font-family: $font-family-header;
  border: 0.1vw solid white;
}
.timeline-table-left-header {
  width: 30%;
  opacity: 0.6;
  color: #2c300a;
  background-color: white;
  font-weight: bold;
  font-family: $font-family-header;
  text-align: right;
  font-size: $size-smallest;
  padding-right: 1vw;
  border: 0.1vw solid white;
  text-align: center;
}

.timeline-table-body {
  width: 35%;
  text-align: center;
  font-weight: bold;
  padding-left: 1vw;
  background-color: $bright-lime;
  border: 0.1vw solid white;
  font-family: $font-family-body;
  font-size: 0.8vw;
}

rect.move-it {
  vertical-align: top;
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  //tablet portrait view
  .title-timeline {
    grid-column: 3 / span 8;
  }

  .scatter-chart.fade-in {
    grid-column: 3 / span 8;
    margin-left: -3.35vw;
    margin-top: -30vw;
  }

  p.timeline-para {
    grid-column: 4 / span 6;
  }
  .timeline-pop-up-container {
    grid-column: 3 / span 8;
  }
  .timeline-table-body {
    width: 35%;
    font-size: 0.7vw;
  }
  .timeline-table {
    width: 60vw;
  }
}

@media (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  //tablet landscape view
  .title-timeline {
    grid-column: 3 / span 8;
  }

  .scatter-chart.fade-in {
    grid-column: 3 / span 8;
    margin-left: -3vw;
    margin-top: -30vw;
  }

  p.timeline-para {
    grid-column: 4 / span 6;
  }
  .timeline-pop-up-container {
    grid-column: 3 / span 8;
  }
  .timeline-table {
    width: 65vw;
  }
}

@media (min-device-width: 2200px) {
  //for monitor view and above space between paragraph and timeline
  .scatter-chart.fade-in {
    margin-top: -1vw;
  }
}
